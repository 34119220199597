import { useSelector } from "react-redux";
import ContentLoader from "react-content-loader";

export const Skeleton = ({ width, height, rounded, uniqueKey }) => {
  const { skin } = useSelector((state) => state.header);

  const isDark = skin === "dark";

  const foregroundColor = isDark ? "#555555" : "#eeeeee";
  const backgroundColor = isDark ? "#333333" : "#cccccc";

  return (
    <ContentLoader
      speed={1}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      uniqueKey={uniqueKey}
    >
      <rect
        x="0"
        y="0"
        rx={rounded}
        ry={rounded}
        width={width}
        height={height}
      />
    </ContentLoader>
  );
};
