import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";
import { Container } from "../../../../components/ui/Container/Container";
import { useCurrentSeo } from "../../../../hooks/useCurrentSeo";
import { TrustBox } from "../../../../components/common/TrustBox/TrustBox";
import { PROXY_TYPES } from "../../../../utils/constants";
import { classNames } from "../../../../utils/helpers/classNames";
import { useIsomorphicLayoutEffect } from "../../../../hooks/useIsomorphicLayoutEffect";
import { Button } from "../../../../components/ui/Buttons/Button/Button";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import { useTranslation } from "../../../../hooks";
import { Animation } from "../../../../components/ui/Animation/Animation";
import "./Index.scss";
import { EnterpriseModal } from "../Prices/components/EnterpriseModal/EnterpriseModal";

export const Index = () => {
  // **Props
  // **Hooks
  const currentSeo = useCurrentSeo();
  const isThemeDark = useIsThemeDark();
  // **Redux State
  const { activeData, countries } = useSelector((state) => state.content);

  const [isClient, setIsClient] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isEnterpriseModalOpen, setIsEnterpriseModalOpen] = useState(false);

  const { index: { buyButton, contactButton, from } } = useTranslation();

  const countryCode = activeData?.country?.code;
  const proxyCode = activeData?.proxy.code;

  const getProxyMinPrice = () => {
    if (!countries?.data?.length || (!countryCode && !proxyCode)) return;

    if (!countryCode) {
      const pricesByProxyCode = countries.data
        .map((country) => country[`min${proxyCode}Price`])
        .filter(Boolean);

      if (!pricesByProxyCode.length) return;

      return Math.min(...pricesByProxyCode);
    }

    const currentCountry = countries.data.find((country) => country.code === countryCode);
    return currentCountry[`min${proxyCode}Price`];
  };

  const paintedTitle = () => {
    if (currentSeo?.mainPageTitle) {
      if (currentSeo?.paintedTitle) {
        const title = currentSeo?.mainPageTitle.split(" ");

        return title.map((item, index) => {
          if (currentSeo?.paintedTitle.includes(item)) {
            return (
              <i
                // className={paintedWords.includes(item) ? "painted" : ""}
                key={index}
              >
                {item}{" "}
              </i>
            );
          }

          return `${item} `;
        });
      } else {
        return currentSeo?.mainPageTitle;
      }
    } else return null;
  };

  const getClass = (baseClass = "index") => {
    const className = baseClass;
    switch (activeData?.proxy?.code) {
      case PROXY_TYPES.ipv4.code:
        return `${className}__ipv4`;

      case PROXY_TYPES.ipv6.code:
        return `${className}__ipv6`;

      case PROXY_TYPES.isp.code:
        return `${className}__isp`;
      case PROXY_TYPES.mobile.code:
        return `${className}__mobile`;

      default:
        return `${className}__ipv4`;
    }
  };

  const getThemePrefix = () => isThemeDark ? "dark" : "light";

  const isIpv4Country = countryCode && activeData?.proxy?.code === PROXY_TYPES.ipv4.code;

  const getAnimationName = () => {
    if (isIpv4Country) {
      return `Country_${getThemePrefix()}`;
    }

    switch (activeData.proxy.code) {
      case PROXY_TYPES.ipv4.code:
        return `IPv4_${getThemePrefix()}`;

      case PROXY_TYPES.ipv6.code:
        return `IPv6_${getThemePrefix()}`;

      case PROXY_TYPES.isp.code:
        return `ISP_${getThemePrefix()}`;

      case PROXY_TYPES.mobile.code:
        return `Mobile_${getThemePrefix()}`;

      default:
        return `IPv4_${getThemePrefix()}`;
    }
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  useIsomorphicLayoutEffect(() => {
    const MIN_SCREEN_WIDTH_FOR_VIDEO = 1200;

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= MIN_SCREEN_WIDTH_FOR_VIDEO);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenEnterpriseModal = () => {
    setIsEnterpriseModalOpen(true);
  };

  const handleCloseEnterpriseModal = () => {
    setIsEnterpriseModalOpen(false);
  };

  const getMainButtonText = () => {
    const minPriceForOneProxy = getProxyMinPrice();

    if (!minPriceForOneProxy) return buyButton;

    return `${from} $${minPriceForOneProxy}`;
  };

  return (
    <section id="index" className={classNames("index", { "index--dark": isThemeDark })}>
      <Container>
        <div className={`index-container index-container-ipv4 ${getClass()}`}>
          <div className="index__video-content">
            <div className="index__video-container">
              {(isClient && isDesktop) && (
                <div className={`index__bg-video index__bg-video--${getAnimationName()}`}>
                  <Animation key={getAnimationName()} animationName={getAnimationName()} />
                </div>
              )}
            </div>
          </div>
          <div className="index-info">
            {isClient && <TrustBox />}
            <h1>{paintedTitle()}</h1>
            <p className="index-subtitle">{currentSeo?.mainPageDescription}</p>
          </div>
          <div className="index__actions">
            {/*<Link to={`/${lang}/#prices`}>*/}
            <Link to="#prices">
              <Button tab="confirm">
                {getMainButtonText()}
              </Button>
            </Link>
            <div className="index__contact-container" onClick={handleOpenEnterpriseModal}>
              <div>{contactButton}</div>
              <IconSvg tag="arrow" />
            </div>
          </div>
          {/*<Form key={activeData?.proxy?.code} />*/}
        </div>
      </Container>
      {isEnterpriseModalOpen && <EnterpriseModal isOpen={isEnterpriseModalOpen} onClose={handleCloseEnterpriseModal} />}
    </section>
  );
};
