import "./CustomInput.scss";
import { useRef, useState } from "react";
import { classNames } from "../../../utils/helpers/classNames";

export const CustomInput = ({ label, value = "", onChange, placeholder, type = "text", defaultValue, className = "", containerClassName = "", labelTop, id = "custom-input", ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const focusInput = () => {
    if (inputRef.current && !isFocused) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={classNames(
        `${containerClassName} custom-input`,
        { "custom-input--focused": isFocused }
      )}
    >
      <input
        ref={inputRef}
        id={id}
        className={`${className} custom-input__input`}
        type={type}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...rest}
      />
      {label && (
        <label onClick={focusInput} htmlFor={id} className={classNames(
          "custom-input__label",
          { "custom-input__label--top": !!value || !!labelTop }
        )}>
          {label}
        </label>
      )}
    </div>
  );
};
