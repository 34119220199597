import { FormProvider, useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../../../../../ui/Buttons/Button/Button";
import { ModalInput } from "../../../../../../ui/ModalInput/ModalInput";
import { emailPattern } from "../../../../../../../utils/constants/emailPattern";
import { passwordPattern } from "../../../../../../../utils/constants/passwordPattern";
import { AgreeWithRules } from "../../../../../agreeWithRules/AgreeWithRules";
import { CfCaptcha } from "../../../../../CfCaptcha/CfCaptcha";
import { ButtonLoader } from "../../../../../../ui/ButtonLoader/ButtonLoader";
import { ApiService } from "../../../../../../../services/ApiService";
import {
  errorToast,
  successToast,
  warningToast
} from "../../../../../../../utils/helpers/customToast.helper";
import { authErrorsHandler } from "../../../../../../../utils/helpers/authErrorsHandler";
import {
  useDispatchedActions,
  useTranslation
} from "../../../../../../../hooks";

export const RegisterForm = () => {
  const { lang } = useParams();
  const reCaptchaRef = useRef(null);

  const { setLoginModal } = useDispatchedActions();

  const [isLoading, setIsLoading] = useState(false);
  const [isAgreeWithConditions, setIsAgreeWithConditions] = useState(false);

  const {
    cabinet: { profile },
    header: { modalSingUp, formElem },
    notifications,
    main: {
      agreeWithRules: { warningMessage }
    }
  } = useTranslation();

  const [cfToken, setCfToken] = useState("");
  const cfCaptchaRef = useRef(null);

  const methods = useForm({
    mode: "onChange"
  });

  const handleLogin = () => setLoginModal(true);

  const register = async () => {
    setIsLoading(true);
    const data = methods.getValues();
    let token = cfToken;

    if (cfCaptchaRef?.current && !token) {
      token = await cfCaptchaRef.current?.getResponsePromise();
    }

    try {
      const { status } = await ApiService.clientRegister({
        email: data.email,
        password: data.password,
        locale: lang,
        captchaToken: token,
        captchaV2: true
      });

      if (status === 206) {
        return errorToast(notifications?.tempMail, lang);
      }

      if (status !== 200) {
        throw status;
      }
      successToast(modalSingUp.successMessage, lang);
      setLoginModal(true);
      cfCaptchaRef?.current?.reset();
    } catch (status) {
      authErrorsHandler(status, "password", lang);
    } finally {
      setIsLoading(false);
      if (reCaptchaRef.current) {
        reCaptchaRef.current.reset();
      }
    }
  };

  const onSubmit = (data) => {
    if (!isAgreeWithConditions) {
      return warningToast(warningMessage, lang);
    }

    if (data.password !== data.rePassword) {
      return;
    }
    register();
  };

  const { errors } = methods.formState;

  const isInvalidEmail = errors.email?.type === "pattern";

  const isPasswordTheSame =
    methods.getValues("password") === methods.getValues("rePassword");

  const isLessThanSixChars =
    errors.password?.type === "validate" ||
    errors.rePassword?.type === "validate";

  const isContainSpaces =
    errors.password?.type === "pattern" ||
    errors.rePassword?.type === "pattern";

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="modal__login-form"
          noValidate={true}
        >
          <ModalInput
            label={formElem.email.label}
            type="email"
            name="email"
            watch={methods.watch}
            rules={{
              required: true,
              pattern: emailPattern
            }}
          />
          {isInvalidEmail && (
            <p className="modal__form-wrong modal__login-hint">
              {formElem.invalidEmail}
            </p>
          )}
          <ModalInput
            label={formElem.password.label}
            type="password"
            name="password"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 6,
              pattern: passwordPattern
            }}
          />
          <ModalInput
            label={formElem.repeatPassword.label}
            type="password"
            name="rePassword"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 6,
              pattern: passwordPattern
            }}
          />
          <div className="modal__login-password-errors">
            {!isPasswordTheSame && (
              <p className="modal__form-wrong modal__login-hint">
                {formElem.wrongPassword}
              </p>
            )}
            {isContainSpaces && (
              <p className="modal__form-wrong modal__login-hint">
                {profile?.wrongPasswordChars}
              </p>
            )}
            {isLessThanSixChars && (
              <p className="modal__form-wrong modal__login-hint">
                {profile?.wrongPasswordLength}
              </p>
            )}
          </div>
          <div className="modal__login-rules">
            <AgreeWithRules
              defaultValue={isAgreeWithConditions}
              handler={() => setIsAgreeWithConditions((prev) => !prev)}
            />
          </div>
          <CfCaptcha ref={cfCaptchaRef} setCfToken={setCfToken} />
          <Button type="submit" disabled={isLoading}>
            {isLoading ? <ButtonLoader /> : modalSingUp.singUpBtn}
          </Button>
        </form>
      </FormProvider>
      <div className="modal__login-footer">
        <span> {modalSingUp.textLogin}</span>
        <Button onClick={handleLogin} initClass="modal__action">
          {modalSingUp.loginBtn}
        </Button>
      </div>
    </>
  );
};
