import { useRef, useState } from "react";
import { useTranslation } from "../../../../../../../../hooks";
import { useIsomorphicLayoutEffect } from "../../../../../../../../hooks/useIsomorphicLayoutEffect";

export const CountryButton = (
  {
    index,
    countriesLength,
    item,
    localCountry,
    handlerChoiceCountry,
    currentWidth,
    currentContainer,
    addToModal,
    modalCountries
  }
) => {
  const [buttonIsVisible, setButtonIsVisible] = useState(true);
  const [modalButton, setModalButton] = useState(true);
  const countryButton = useRef(null);
  const more = useRef(null);
  const { prices } = useTranslation();

  useIsomorphicLayoutEffect(() => {
    setModalButton(false);

    if (currentContainer && countryButton.current) {
      let CONTAINER_PADDINGS = 48;
      let MARGIN_RIGHT = 16;
      let MORE_BUTTON = 100;

      const containerStyles = window.getComputedStyle(currentContainer);
      const buttonStyles = window.getComputedStyle(countryButton.current);

      const leftPadding = parseFloat(containerStyles.paddingLeft);
      const rightPadding = parseFloat(containerStyles.paddingRight);

      CONTAINER_PADDINGS = leftPadding + rightPadding;
      MARGIN_RIGHT = parseFloat(buttonStyles.marginRight);
      MORE_BUTTON = more.current.getBoundingClientRect().width;

      const { width } = countryButton.current.getBoundingClientRect();

      currentWidth.current += width + MARGIN_RIGHT;

      if (index === countriesLength - 1 && modalCountries?.length === 0) {
        if (currentWidth.current > currentContainer.offsetWidth - CONTAINER_PADDINGS) {
          setButtonIsVisible(false);
          addToModal(item);
        }

        return;
      }

      if (currentWidth.current > currentContainer.offsetWidth - CONTAINER_PADDINGS - MORE_BUTTON) {
        setButtonIsVisible(false);
        addToModal(item);
      }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContainer, item, currentWidth]);

  return (
    <>
      { buttonIsVisible && (
        <li className="countries-item" ref={countryButton}>
          <button
            className={`countries-button ${
              localCountry.id === item?.id ? "countries-button--active" : ""
            }`}
            // to={`/${lang}/${activeData?.proxy?.code === "IPv4" ? `${item?.url}/` : `${activeData?.proxy?.value}/`}`}
            onClick={() => handlerChoiceCountry(item)}
          >
            {item?.label || item?.code}
          </button>
        </li>
      )}
      {modalButton && (
        <li className="countries-item" key={"more"}>
          <button
            className="visually-hidden-snippet countries-button countries-button-more"
            ref={more}
          >
            {`${prices?.more} +${modalCountries?.length}`}
          </button>
        </li>
      )}
    </>
  );
};
