import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../../../components/ui/Buttons/Button/Button";
import { emailPattern } from "../../../../utils/constants/emailPattern";
import { ApiService } from "../../../../services/ApiService";
import { errorToast, successToast, warningToast } from "../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../components/ui/ButtonLoader/ButtonLoader";
import { useTranslation } from "../../../../hooks";
import { CustomInput } from "../../../../components/ui/CustomInput/CustomInput";

export const FeedbackForm = () => {
  const { lang } = useParams();

  const {
        contacts:
          { feedbackForm:
            {
              formTitle,
              nameField,
              emailField,
              messageField,
              submit,
              success,
              invalidEmail,
              invalidMessage
            } }
  } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    const resetForm = () => {
        setFormData({ name: "", email: "", message: "" });
    };
    // todo добавить дебаунс
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const onSubmit = async () => {
        if (!emailPattern.test(formData.email)) {
            warningToast(invalidEmail, lang);

            return;
        }

        if (formData.message.length < 10) {
            warningToast(invalidMessage, lang);

            return;
        }

        try {
            setIsLoading(true);

            const { status } = await ApiService.sendFeedback(formData);

            if (status !== 200) {
                throw status;
            }

            successToast(success, lang);
            resetForm();
        } catch (e) {
            errorToast("Something went wrong");
        } finally {
            setIsLoading(false);
        }
    };

    return (
      <div className="contacts__form">
          <div className="contacts__form__title">{formTitle}</div>
          <div className="contacts__form__box-self-data">
              <CustomInput
                label={nameField}
                value={formData.name}
                onChange={handleChange}
                name="name"
              />
              <CustomInput
                type="email"
                id="custom-input__email"
                label={emailField}
                value={formData.email}
                onChange={handleChange}
                name="email"
              />
          </div>
          <textarea
            className="contacts__form__input contacts__form__message"
            placeholder={messageField}
            value={formData.message}
            name="message"
            onChange={handleChange}
          />
          <Button
            tab="confirm"
            onClick={onSubmit}
            initClass="contacts__form__button"
            disabled={isLoading}
          >
              {isLoading ? <ButtonLoader /> : submit }
          </Button>
      </div>
    );
};
