import { useMatch } from "react-router-dom";
import { MobileProxyReducerActions } from "../../redux/reducers/MobileProxyReducer/MobileProxy.reducer";
import { ContentReducerActions } from "../../redux/reducers/ContentReducer/Content.reducer";
import { HeaderReducerActions } from "../../redux/reducers/HeaderReducer/Header.reducer";
import { ApiService } from "../../services/ApiService";
import { SSLSecure } from "../../components/common/SSLSecure/SSLSecure";
import { PROXY_TYPES } from "../../utils/constants";
import { Seo } from "./components/Seo/Seo";
import { FaqSection } from "./components/FaqSection/FaqSection";
import { Feedback } from "./components/Feedback/Feedback";
import { Index } from "./components/Index/Index";
import { Prices } from "./components/Prices/Prices";
import { Tips } from "./components/Tips/Tips";
import { ProxyManadger } from "./components/ProxyManadger/ProxyManadger";
import { OurClients } from "./components/OurClients/OurClients";
import { Benefits } from "./components/Benefits/Benefits";

const MainPage = () => {
  const matchIPv4 = useMatch("/:lang");

  return (
    <>
      <Index />
      <Tips />
      <Prices />
      <SSLSecure />
      {matchIPv4 ? <Benefits /> : <ProxyManadger />}
      <Feedback />
      <OurClients />
      <FaqSection />
      <Seo />
    </>
  );
};

const getInitCountryByProxyType = ({ countries, initCountry, proxyCode }) => {
  if (initCountry) return initCountry;

  if (!countries?.length) return;

  const filteredCountries = countries.filter((country) => country.showOnFrontType.includes(proxyCode));

  if (proxyCode === PROXY_TYPES.ipv6.code) {
    return filteredCountries.reduce((minCountryByPrice, country) => {
      if (country.minIPv6Price < minCountryByPrice.minIPv6Price) return country;

      return minCountryByPrice;
    });
  }

  return filteredCountries.reduce((mostPopularCountry, country) => {
    const searchValue = `${proxyCode.toLowerCase()}PopularIndex`;

    if (country[searchValue] > mostPopularCountry[searchValue]) return country;

    return mostPopularCountry;
  });

};

const getInitPriceItems = async ({ countries, initCountry, proxyCode, store }) => {
  if (!proxyCode) return;

  const country = getInitCountryByProxyType({ countries, initCountry, proxyCode });

  if (!country) return;

  const { data } = await ApiService.getPriceItems({
    countryId: country.id,
    proxyType: proxyCode
  });

  const initData = {
    [`${proxyCode}:${country.id}`]: data || {}
  };

  store.dispatch(ContentReducerActions.setPriceItemsInitData(initData));
};

MainPage.getServerSideState = async (store, params) => {
  const baseURL = process.env.REACT_APP_SSR_API_URL;

  const [
    content,
    proxyParams,
    countries,
    rentPeriod
    // faqByParams
    // eslint-disable-next-line no-undef
  ] = await Promise.all([
    ApiService.getFullContentSSR(baseURL, params.locale),
    ApiService.getFrontConfigSSR(baseURL, params.userIp),
    ApiService.getAllCountriesSSR(baseURL, params.userIp),
    ApiService.getRentPeriodsSSR(baseURL)
    // ApiService.getFaqByParamsSSR(baseURL, params.proxy, params.country || params.countryFromQuery)
  ]);

  let faqByParams = {};

  const countryUrl = params.country || params.countryFromQuery;
  const isSuchCountryExist = countries?.data && countries?.data?.find(({ url }) => url === countryUrl);

  if (isSuchCountryExist || !countryUrl) {
    faqByParams = await ApiService.getFaqByParamsSSR(baseURL, params.proxy, params.country || params.countryFromQuery);
  }

  store.dispatch(ContentReducerActions.setFaqByParams(faqByParams.data || {}));
  store.dispatch(HeaderReducerActions.setDarckTheme(params.theme));
  store.dispatch(
    ContentReducerActions.setSiteContentSSR({
      data: content.data,
      locale: params.locale
    })
  );

  try {
    await getInitPriceItems(
      {
        countries: countries?.data || [],
        initCountry: isSuchCountryExist,
        proxyCode: params.proxy,
        store
      }
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  store.dispatch(ContentReducerActions.setSiteProxyParamsSSR(proxyParams.data));

  const tempTypeProxy = store
    .getState()
    .content.proxies?.enabled.find((item) => item?.code === params.proxy);

  if (tempTypeProxy?.code) {
    store.dispatch(ContentReducerActions.setActiveProxy(tempTypeProxy));
  }

  const currencyId = store.getState().content?.activeData?.currency?.id;
  if (currencyId) {
    const mobileTariff = await ApiService.getMobileProxySSR(baseURL, currencyId);
    store.dispatch(
      MobileProxyReducerActions.setMobileTariffsSSR({
        data: mobileTariff.data,
        currencyId
      })
    );
  }

  const country = countries?.data
    ?.filter((item) => item.showOnFrontType.includes(params.proxy))
    ?.find((item) => item.url === countryUrl);

  const countryId = country?.id || "";
  const countryCode = country?.code || "";

  store.dispatch(
    ContentReducerActions.setActiveCountry({
      id: params.proxy !== "MOBILE" ? countryId : "",
      code: params.proxy !== "MOBILE" ? countryCode : ""
    })
  );

  const finalSeoInfo = await ApiService.getSeoInfoSSR(baseURL, {
    countryId: country?.id || "",
    localeCode: params.locale,
    proxyType: params.proxy
  });

  store.dispatch(ContentReducerActions.setUserAuthData({ isAuth: false, email: "" }));
  store.dispatch(ContentReducerActions.setAllCountriesSSR(countries?.data));
  store.dispatch(ContentReducerActions.setRentPeriodsSSR(rentPeriod.data));

  store.dispatch(
    ContentReducerActions.setSiteSeoSSR({
      data: finalSeoInfo.data,
      countryId: country?.id || "",
      localeCode: params.locale,
      proxyType: params.proxy
    })
  );
};

export default MainPage;
