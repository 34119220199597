import { Button } from "../../../../../../components/ui/Buttons/Button/Button";
import { classNames } from "../../../../../../utils/helpers/classNames";
import { useTranslation } from "../../../../../../hooks";
import "./PriceItemTemplate.scss";
import { ButtonLoader } from "../../../../../../components/ui/ButtonLoader/ButtonLoader";

export const PriceItemTemplate = ({
  children,
  isPopular,
  handler,
  buttonText,
  isLoading
}) => {
  const {
    prices: {
      buyBtn,
      priceItem: { popular }
    }
  } = useTranslation();

  return (
    <div
      className={classNames("price-item-template", {
        "price-item-template--popular": isPopular
      })}
      {...(isPopular ? { "data-popular": popular } : {})}
    >
      {children}
      <Button onClick={handler} tab="price-item" disabled={isLoading}>
        {isLoading ? <ButtonLoader /> : buttonText || buyBtn}
      </Button>
    </div>
  );
};
